@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;600;700&display=swap");

@tailwind base;
@tailwind components;

:root {
  --bc: 214 30% 32%;
  --b2: 217 100% 97%;
}
.App {
  background-image: radial-gradient(
    hsla(214 30% 32% / 0.2) 0.5px,
    hsla(217 100% 97% / 1) 0.5px
  );
  background-size: 5px 5px;
}
.form-control {
  @apply my-2;
}
.label-text {
  @apply font-semibold;
}
.shadow-wrap {
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1));
}

.card .card-body :where(p) {
  flex-grow: 0;
  @apply mb-5;
}
.card .card-body :where(p):last-of-type {
  flex-grow: 1;
}

.card-actions .btn {
  @apply w-full lg:w-fit;
}

.fancy-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.fancy-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.limitHeight {
  max-height: 40vh;
}
@media (min-width: 768px) {
  .limitHeight {
    max-height: 70vh;
  }
}

.card main {
  max-height: calc(100vh - 350px);
}

@media (min-width: 768px) {
  .card-body {
    max-height: calc(70vh);
  }
}

:root {
  --bg-image: url("https://vosreinaert.be/wp-content/uploads/2018/08/vos_banner_big_2.jpg");
}

body,
html {
  background-color: #1d1e22;
}

#root {
  background-color: transparent;
}

#root::before {
  content: "";
  background-image: var(--bg-image);
  background-size: cover;
  height: 100vh;
  width: 100vw;
  position: fixed;
  filter: blur(30px) contrast(50%);
  /* z-index: -1; */
}
.App {
  background: none;
  background-color: transparent;
}

@tailwind utilities;
